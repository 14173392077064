/* eslint-disable react/button-has-type */
import type { GetServerSideProps, GetServerSidePropsContext } from "next";

import { ReactElement, useEffect, useState } from "react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { I18Text } from "src/types/i18Text";
import { ParsedUrlQuery } from "querystring";
import { useRouter } from "next/router";
import { logError } from "src/utils/logger";
import getChannelConfigByPathName from "src/api/channel";
import { checkUnknownError } from "src/utils/errors";
import CHANNEL_SIZE from "src/constants/channel";
import {
  LOGICAL_GROUP_CHANNEL_CODE,
  RETAIL_LOGICAL_GROUP_CHANNEL_CODE,
} from "src/constants/logicalGroupChannelCode";
import {
  filterParams,
  formatPathByLogicalGroupChannel,
  getUrlWithQuery,
} from "src/utils/url";
import { DROP_LEAD_ANCHOR } from "src/constants/dropLeadForm";
import { GLOBAL_PARAMS } from "src/constants/params";
import { withLayout } from "src/components/Layout/withLayout";
import dynamic from "next/dynamic";
import withUserOrGuest from "src/components/Auth/withUserOrGuest";
import { USPBannerComponentProps } from "../../components/LandingPage/USPBanner";
import { BannerProps } from "../../components/LandingPage/Banner";
import { USPListComponentProp } from "../../components/LandingPage/USPList/USPList";
import { PartnerProps } from "../../components/LandingPage/Partner";
import {
  getProductCategoryActiveList,
  getProductCategoryActiveListViaServerSide,
} from "../../api/productCategory";
import { AppPropType } from "../_app";

export type HomeProps = {
  channelPath: string;
  channelCode: string;
  popup: I18Text;
  USPBannerProps: USPBannerComponentProps;
  USPitemsProps: USPListComponentProp;
  bannerProps: Omit<BannerProps, "isRET">;
  partnerProps: PartnerProps;
  sRefCode?: string;
  isFromCarnival: boolean;
};

const Banner = dynamic(() => import("../../components/LandingPage/Banner"), {
  loading: () => <div />,
});

const ProductCategory = dynamic(
  () => import("../../components/LandingPage/ProductCategory"),
  {
    loading: () => <div />,
  }
);

const RetailProductCategory = dynamic(
  () =>
    import(
      "src/components/LandingPage/RetailProductCategory/RetailProductCategory"
    ),
  {
    loading: () => <div />,
  }
);

const ContactUs = dynamic(
  () => import("../../components/LandingPage/ContactUs"),
  {
    loading: () => <div />,
  }
);

const HighlightPlan = dynamic(
  () => import("src/components/LandingPage/HighlightPlan/HighlightPlan"),
  {
    loading: () => <div />,
  }
);

const USPBanner = dynamic(
  () => import("../../components/LandingPage/USPBanner"),
  {
    loading: () => <div />,
  }
);

const USPList = dynamic(
  () => import("../../components/LandingPage/USPList/USPList"),
  {
    loading: () => <div />,
  }
);

const Partner = dynamic(() => import("../../components/LandingPage/Partner"), {
  loading: () => <div />,
});

const DropLead = dynamic(() => import("src/components/DropLead/DropLead"), {
  loading: () => <div />,
});

const PopupAdsContent = dynamic(
  () => import("src/components/LandingPage/PopupAdsContent/PopupAdsContent"),
  {
    ssr: false,
    loading: () => <div />,
  }
);

export interface CustomParams extends ParsedUrlQuery {
  channelPath: string;
}

const HomePage = ({
  channelPath,
  channelCode,
  USPBannerProps,
  USPitemsProps,
  bannerProps,
  partnerProps,
  sRefCode,
  isFromCarnival,
  channelConfig,
}: HomeProps & AppPropType): ReactElement => {
  const { content } = channelConfig;

  const router = useRouter();
  const isFromDropLead = router.asPath?.split("#")[1] === DROP_LEAD_ANCHOR;
  const isRET =
    channelConfig.logicalGroupChannelCode === RETAIL_LOGICAL_GROUP_CHANNEL_CODE;

  const [productCategories, setProductCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProductCategories = async () => {
      setIsLoading(true);
      try {
        const productCat = await getProductCategoryActiveList(channelCode);
        setProductCategories(productCat.sort((a, b) => a.seq - b.seq));
        setIsLoading(false);
      } catch (error) {
        logError("Error fetch product category", { error });
      }
    };
    getProductCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Banner {...bannerProps} content={content} isRET={isRET} />
      {!isRET && (
        <ProductCategory
          isRET={isRET}
          isLoading={isLoading}
          productCategories={productCategories}
          channelPath={channelPath}
          content={content}
        />
      )}
      {isRET && (
        <RetailProductCategory
          isLoading={isLoading}
          productCategories={productCategories}
          channelPath={channelPath}
          content={content}
        />
      )}
      <HighlightPlan content={content} isRET={isRET} />
      <ContactUs content={content} />
      <USPBanner image={USPBannerProps.image} content={content} />
      <USPList {...USPitemsProps} content={content} />
      <Partner {...partnerProps} content={content} />
      {content?.dropLead?.enabled && (
        <DropLead
          channelConfig={channelConfig}
          sRefCode={sRefCode}
          isFromCarnival={isFromCarnival}
          channelPath={channelPath}
        />
      )}
      {!isFromDropLead && <PopupAdsContent content={content} />}
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<HomeProps, CustomParams> =
  async (context: GetServerSidePropsContext<CustomParams>) => {
    const channelPath = context.params?.channelPath;
    const sRefCode =
      typeof context.query?.sRefCode === "object"
        ? context.query?.sRefCode[0]
        : context.query?.sRefCode || null;
    const { query } = context;
    const carnivalPrefixs = JSON.parse(
      process.env.NEXT_PUBLIC_RETAIL_CARNIVAL_S_REF_CODE_PREFIXS
    ) as string[];
    const isFromCarnival = carnivalPrefixs.some((carnivalPrefix) =>
      sRefCode
        ?.toLocaleLowerCase()
        ?.startsWith(carnivalPrefix?.toLocaleLowerCase())
    );

    try {
      const channelConfig = await getChannelConfigByPathName(channelPath);
      if (!channelConfig) {
        return {
          notFound: true,
        };
      }

      if (
        channelConfig.logicalGroupChannelCode !== LOGICAL_GROUP_CHANNEL_CODE
      ) {
        return {
          notFound: true,
        };
      }

      const { content, code: channelCode, size } = channelConfig;

      // redirect to product category when on home page and channel size M
      if (size === CHANNEL_SIZE.M) {
        const { res } = context;

        const productCategoryList =
          await getProductCategoryActiveListViaServerSide(channelCode);

        const validProductCategory = productCategoryList[0];
        if (res) {
          res.writeHead(307, {
            Location: getUrlWithQuery(
              formatPathByLogicalGroupChannel(
                `/${channelPath}/${validProductCategory.code}`
              ),
              filterParams(query, GLOBAL_PARAMS)
            ),
          });
          res.end();
        }
      }

      return {
        props: {
          popup: {
            th: process.env.NEXT_LANDING_PAGE_POPUP_TH || null,
            en: process.env.NEXT_LANDING_PAGE_POPUP_EN || null,
          },
          USPBannerProps: {
            image:
              "https://health.staging.easysunday.com/static/images/non_partner_manual.png",
            content,
          },
          USPitemsProps: {
            items: [
              {
                title: {
                  th: "ซื้อออนไลน์ง่ายๆ",
                  en: "Easy to buy.",
                },
                description: {
                  th: "เราทำให้ประสบการ์การซื้อประกันของคุณเหมือนกับการช้อปปิ้งบน e-commere พร้อมกับช่องทางการชำระเงินที่หลากหลาย ผ่อน 0% ได้อีกด้วย!",
                  en: "Shop insurance online just like you shop on e-commerce platform! We also provide many payment option with 0% installment.",
                },
                image: "/static/pages/landing/usp-key-1.svg",
              },
              {
                title: {
                  th: "ทำทุกอย่างได้ในแอป",
                  en: "Access all the cares\nin your hand.",
                },
                description: {
                  th: "เข้าถึงบริการต่างๆ ในซูเปอร์แอปฯ ได้อย่างเต็มรูปแบบ ทั้งปรึกษาแพทย์ออนไลน์, ตรวจสุขภาพกับ AI, รับสิทธิพิเศษจากพริวิเลจ โปรแกรม, เคลมออนไลน์, และอื่นๆ อีกมากมาย",
                  en: "Get the full access to many services on super app and enjoy Telemedicine, AI health checks, Privilege Program, Claim online, fun campaign and many more.",
                },
                image: "/static/pages/landing/usp-key-2.svg",
              },
              {
                title: {
                  th: "รักษา หรือซ่อมแซม\nโดยไม่ต้องสำรองจ่ายได้ทั่วประเทศ",
                  en: "Fixes and cures nationwide.",
                },
                description: {
                  th: "ดูแลทุกความเจ็บป่วย ซ่อมแซมทุกความ เสียหายได้ทันทีโดยไม่ต้องสำรองจ่าย ที่โรงพยาบาล หรือศูนย์ซ่อมบำรุงในเครือข่าย ทั่วประเทศ",
                  en: "All of your sickness will be cured and your broken things will be fixed at a nationwide network hospitals, garages, or repair centers without paying out of pockets.",
                },
                image: "/static/pages/landing/usp-key-3.svg",
              },
              {
                title: {
                  th: "เคลมออนไลน์ทันใจ\nรับเงินได้ในไม่กี่ขั้นตอน",
                  en: "Claim in few clicks away.",
                },
                description: {
                  th: "จะเคลม หรือเช็กสถานะการเคลม ก็สามารถ ทำได้ง่ายๆ ผ่านทางเว็บไซต์ หรือซูเปอร์แอปฯ ลืมเอกสารมากมายที่มีขั้นตอนวุ่นวายไปได้เลย",
                  en: "Submit or track your claims, you can do it all online. With just a few easy steps on our website or our super app. No more paperwork.",
                },
                image: "/static/pages/landing/usp-key-4.svg",
              },
              {
                title: {
                  th: "ชีวิตดีๆ กับสิทธิพิเศษมากมาย",
                  en: "Exclusive privilege\nonly for you.",
                },
                description: {
                  th: "รับสิทธิพิเศษมากมายจากพาร์ทเนอร์บนพริวิเลจโปรแกรมที่คัดสรรมาเพื่อช่วยให้คุณใช้ชีวิตได้ดียิ่งขึ้น ทั้งทาง ร่างกาย และจิตใจ",
                  en: "Enjoy our special offers from partner merchants on Privilege Program that will support you toward living healthily for your mind and body.",
                },
                image: "/static/pages/landing/usp-key-5.svg",
              },
              {
                title: {
                  th: "ซื้อประกันออนไลน์ได้ง่าย\nในราคาที่ใช่",
                  en: "Buy insurance online at a great price.",
                },
                description: {
                  th: "ซื้อประกัน และรับความคุ้มครองได้ตลอดเวลา ผ่านช่องทางออนไลน์ สะดวก รวดเร็ว และปลอดภัย ในราคาที่ใครก็เอื้อมถึง",
                  en: "You can get affordable, personalized coverage from the comfort of your couch. It's fast, simple, and secure to buy it online. ",
                },
                image: "/static/pages/landing/usp-key-6.svg",
              },
            ],
            content,
          },
          bannerProps: {
            image: "/static/pages/landing/retail-banner.svg",
            content,
          },
          partnerProps: {
            title: {
              th: "พาร์ทเนอร์บริษัทประกันภัยชั้นนำของเรา",
              en: "Our leading insurer partners.",
            },
            subTitle: {
              th: "เพื่อให้คุณได้อุ่นใจและมั่นใจไปกับซันเดย์ เราได้จับมือกับบริษัทประกันภัยชั้นนำ\nเพื่อร่วมสร้างแผนประกันที่มีความคุ้มครองและผลประโยชน์ที่ดีที่สุดให้กับคุณ",
              en: "To give you peace of mind and confidence, we’ve teamed up with our leading insurer partners\nto create an insurance plan that brings you the best coverage and benefits.",
            },
            logos: [
              "/static/pages/landing/ksk-logo.svg",
              "/static/pages/landing/chubb-logo.svg",
              "/static/pages/landing/mbklife-logo.svg",
              "/static/pages/landing/tiplife-logo.svg",
              "/static/pages/landing/aia-logo.svg",
              "/static/pages/landing/lmg-logo.svg",
              "/static/pages/landing/msig-logo.svg",
              "/static/pages/landing/thaivivat-logo.svg",
              "/static/pages/landing/cigna-logo.svg",
            ],
            content,
          },
          channelCode,
          channelPath,
          sRefCode,
          isFromCarnival,
          ...(await serverSideTranslations(context.locale, [
            "main-page",
            "common",
            "cookie-consent",
            "layout",
            "otp-form",
            "drop-lead",
            "form",
            "technical-error-modal",
            "highlight-plan-card",
            "insured-person-form",
            "category-list",
          ])),
        },
      };
    } catch (error) {
      const isUnknownError = checkUnknownError(error);

      if (!isUnknownError) {
        return {
          notFound: true,
        };
      }

      throw error;
    }
  };

export default withUserOrGuest(withLayout(HomePage));
