import { axiosErrorUnwrap } from "src/utils/axiosError";
import axios from "axios";
import { User } from "src/types/user";

export type SendAuthOtpRes = {
  referenceNumber: string;
  isRegisteredMobileNo: boolean;
};
/**
 * send otp to verify mobile number for authentication
 * @param mobileNo target mobile number
 * @param lang otp langauge message
 * @returns reference number otp and is found user in retail platform flag
 */
export const sendAuthOtp = async (
  mobileNo: string,
  lang: string
): Promise<SendAuthOtpRes> => {
  try {
    const res = await axios.post<any>(
      "/api/retail/auth/send-otp",
      { mobileNo },
      {
        headers: {
          "Accept-Language": lang,
        },
      }
    );
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};

/**
 * get user profile from access token in cookies
 * @returns user's profile
 */
export const getUserProfile = async (): Promise<User> => {
  try {
    const res = await axios.get<any>("/api/retail/auth/user/me");
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};

/**
 * Function to verify authentication.
 * @return atk, rtk, isAlreadyRegistered and expired datetime
 */
export const verifyAuth = async (payload: object) => {
  try {
    const res = await axios.post<any>("/api/retail/auth/login", payload);
    const result = { ...res.data, timestamp: res.headers.date };
    return result;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};

/**
 * logout by access token in cookies
 * @returns empty object
 */
export const logout = async (): Promise<User> => {
  try {
    const res = await axios.post<any>(
      "/api/retail/auth/logout",
      {},
      {
        withCredentials: true,
        headers: { credentials: "same-origin" },
      }
    );
    return res.data;
  } catch (error) {
    const errorUnwrap = axiosErrorUnwrap(error);
    throw Object.assign(new Error(errorUnwrap.message), errorUnwrap);
  }
};
