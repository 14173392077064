import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { getUserProfile } from "src/api/auth";
import { HEADER_TYPE } from "src/constants/layout";
import {
  LOGICAL_GROUP_CHANNEL_CODE,
  RETAIL_LOGICAL_GROUP_CHANNEL_CODE,
} from "src/constants/logicalGroupChannelCode";
import { User } from "src/types/user";

export type WithUserOrGuestProps = {};

// allow user or guest to access this page
// if user is authorized, header will display user header with user data to child component.
// if user not authorized, header will display guest header and able to log in/sign up.
const withUserOrGuest = (WrappedComponent) => {
  return (props: WithUserOrGuestProps) => {
    const router = useRouter();
    const [user, setUser] = useState<User>(null);
    const [isLoadSuccess, setIsLoadSuccess] = useState(false);
    const isRetail =
      LOGICAL_GROUP_CHANNEL_CODE === RETAIL_LOGICAL_GROUP_CHANNEL_CODE;

    useEffect(() => {
      const fetchGetProfile = async () => {
        try {
          const result = await getUserProfile();
          setUser(result);
        } catch (err) {
          setUser(null);
        }
        setIsLoadSuccess(true);
      };

      // fetch user data once only, prevent unnecessary fetch from other state.
      if (!isLoadSuccess) {
        fetchGetProfile();
      }
    }, [isLoadSuccess, router]);

    return (
      <>
        {isLoadSuccess && (
          <WrappedComponent
            {...props}
            user={user}
            headerType={isRetail ? HEADER_TYPE.PROFILE : HEADER_TYPE.PARTNER}
          />
        )}
      </>
    );
  };
};

export default withUserOrGuest;
